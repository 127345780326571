import { TaskKind } from './api-client';
export const communicationsServiceMessaging = 'communicationsServiceMessaging';
export const nonOnboardedUsersView = 'non_onboarded_users_view';
export const videoVisitNotes = 'videoVisitNotes';
export const spellCheck = 'spellCheck';
export const kitDeliveredNoEt = 'kit_delivered_no_et';
export const ensoYearTwo = 'y2_enso_task';
export const ensoUtilization = 'ensoUtilization';
export const experimentTags = 'experiment_tags';
export const lowMedTierTags = 'lowMedTierTags';
export const useNewMessagingBffEndpoint = 'use_new_messaging_bff_endpoint';
export const memberAlertsCutover = 'member-alerts-cutover';
export const readyForCoaching = 'ready_for_coach_task';
export const useNewMessagingBffPostMessageEndpoint = 'use_new_messaging_bff_post_message_endpoint';
export const useNewMessagingBffMarkAsReadEndpoint = 'use_new_messaging_bff_mark_as_read_endpoint';
export const useNewMessagingBffConversationsEndpoint = 'use_new_messaging_bff_get_conversations';
export const useNewMessagingBffUpdateMessageEndpoint = 'use_new_messaging_bff_update_message';
export const useNewMessagingBffDeleteMessageEndpoint = 'use_new_messaging_bff_delete_message';
export const healthLogNotesFilterFlag = 'member-health-log-ctt';
export const painUptickFilterFlag = 'health-log-pain-upticks-ctt';
export const zoomPhoneCallEnabled = 'zoom-phone-call-enabled';

export interface FeatureFlagToTaskKinds {
  [featureFlag: string]: TaskKind[];
}

export const FEATURE_FLAGS_TO_TASK_KINDS: FeatureFlagToTaskKinds = {
  [kitDeliveredNoEt]: [TaskKind.kitDeliveredNoEt],
  [ensoYearTwo]: [TaskKind.ensoYearTwo],
  [readyForCoaching]: [TaskKind.readyForCoaching]
};

// Any new treatments needs to be added here in order to be registered with the split sdk
export const REGISTER_TREATMENT_LIST = [
  nonOnboardedUsersView,
  communicationsServiceMessaging,
  videoVisitNotes,
  kitDeliveredNoEt,
  ensoYearTwo,
  ensoUtilization,
  experimentTags,
  lowMedTierTags,
  memberAlertsCutover,
  readyForCoaching,
  useNewMessagingBffEndpoint,
  useNewMessagingBffPostMessageEndpoint,
  useNewMessagingBffMarkAsReadEndpoint,
  useNewMessagingBffConversationsEndpoint,
  useNewMessagingBffUpdateMessageEndpoint,
  useNewMessagingBffDeleteMessageEndpoint,
  healthLogNotesFilterFlag,
  painUptickFilterFlag,
  zoomPhoneCallEnabled
];
