import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Route, RouteProps, useLocation } from 'react-router-dom';
import { useHingeHealthSecurityContext } from '../../components/auth-shared';
import Spinner from '../../components/spinner/Spinner';
import { DispatchThunk } from '../../redux/actions';
import { actionCreators } from '../../redux/actions/auth';

type Props = RouteProps;

const OktaRoute = ( props: Props ) => {
  const location = useLocation();
  const { hingeHealthAuthClient, hingeHealthAuthState } = useHingeHealthSecurityContext();
  const dispatch: DispatchThunk = useDispatch();
  const [renderRoute, setRenderRoute] = React.useState(false);

  React.useEffect(() => {
    const asyncCheckOktaAuth = async() => {
      const isAuthenticated = await hingeHealthAuthClient.isAuthenticated();

      if (!isAuthenticated) {
        hingeHealthAuthClient.signInWithRedirect({ originalUri: location.pathname});
      } else {
        dispatch(actionCreators.validateOkta())
          .then(() => setRenderRoute(true));
      }
    };

    if (!hingeHealthAuthState) {
      return;
    }

    asyncCheckOktaAuth();
  }, [hingeHealthAuthClient, hingeHealthAuthState]);

  if (!hingeHealthAuthState?.isAuthenticated || !renderRoute) {
    return <Spinner />;
  }

  return <Route {...props}/>;
};

export default React.memo(OktaRoute);
